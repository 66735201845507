import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/zap-onboard-api/zap-onboard-api/services-unmanaged/zap-onboard-home-page/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "migrate-employees-from-one-xero-organisation-to-another"
    }}>{`Migrate Employees from One Xero Organisation to Another`}</h1>
    <p>{`If a team of employees need to be moved from one Xero organisation to another follow these steps:`}</p>
    <ol>
      <li parentName="ol">{`Create a new Canyou business `}<a parentName="li" {...{
          "href": "/support/businesses/add-a-business"
        }}>{`(Documentation)`}</a></li>
      <li parentName="ol">{`Link the new Canyou business to the old Xero organisation `}<a parentName="li" {...{
          "href": "/support/integrations/xero/setup"
        }}>{`(Documentation)`}</a></li>
      <li parentName="ol">{`Import the employees from the old Xero organisation into the new Canyou business `}<a parentName="li" {...{
          "href": "/support/integrations/xero/import-employees"
        }}>{`(Documentation)`}</a></li>
      <li parentName="ol">{`Update the Xero connection so that the new Canyou business is linked to the new Xero organisation (Same as step 2)`}</li>
    </ol>
    <p>{`Now you have moved all the employees into the new Canyou business. You are now able to send out new
workflows to start collecting employee information. Once collected you will be able to sync the updated data into Xero.`}</p>
    <p>{`Great work !! 🎉`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      